@import "~antd/dist/antd.css";

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  overflow-wrap: inherit;
}

.ant-table-thead tr th {
  font-weight: 700;
  line-height: 16px;
}
.ant-table-tbody tr td {
  font-weight: 400;
  line-height: 14px;
  padding: 0px 10px;
}
