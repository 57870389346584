.registertoBidText {
  display: block !important;
  margin-top: 30px;
  text-align: center;
  padding-bottom: 0px !important;
  min-height: 172px !important;
}
.registertoBidHead {
  /* font-family: Noto Serif; */
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
}

.rejectTextarea {
  width: 450px;
  margin-left: 67px;
  margin-bottom: 37px;
  max-width: 85%;
}
.subtextPageTitle {
  /* font-family: Noto Serif; */
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
}
.subtextPage {
  /* font-family: Noto Serif; */
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
}
.subtextPage label {
  cursor: pointer;
}
.bidBottomText {
  /* font-family: Noto Serif; */
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
}
.imageUrlForPopupName {
  margin: 50px;
}
.imageUrlForPopupName img {
  max-width: 100%;
}
