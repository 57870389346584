#navbarCollapse {
  display: flex !important;
  align-items: center;
  width: 100%;
  box-shadow: 0 4px 12px 0 rgb(0 0 0 / 5%);
  background-color: #fff;
  height: 70px;
  padding: 10px 50px;
  color: #ff5733;
  position: sticky;
  top: 0;
  z-index: 1;
  /* background-color: #e2e2e2; */
}

#headerWrapper {
  height: 60px;
  background-color: #fff;
  position: fixed;
  width: 100%;
  z-index: 99999;
}

.header-popover-box {
  z-index: 50;
}

.welcome-text-sec-top {
  padding-top: 70px;
  background: #fff;
}

.welcome-text-sec-top-con {
  background: #f0f3f6;
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;
  color: #214287;
}

.logo-text-main {
  cursor: pointer;
  font-size: 40px;
  text-transform: capitalize;
  line-height: 50px;
  display: block;
}

.mainLogoImage {
  max-width: 140px;
  max-height: 65px;
  margin-top: -7px;
}

#navbarCollapse ul {
  flex-direction: row;
  /* margin-right: auto !important;
    margin-left: auto !important;
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none; */
}

.leftsideItem img {
  cursor: pointer;
}

.signed-user-name {
  margin-top: 7px;
  margin-right: 5px;
}

.signInButtonArea {
  margin-left: 25px;
  display: flex;
}

.signInButton {
  background-color: #ff5733;
  color: #fff;
  border: 1px solid #fff;
  padding: 5px 35px;
  border-radius: 4px;
  height: 45px;
}

#navbarCollapse ul li {
  display: inline;
  /* padding: 0px 20px; */
}

.centersideItem input {
  width: 300px;
  height: 38px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding-left: 10px;
}

.cart_items_count {
  position: absolute;
  margin-top: -5px;
  color: #222020;
}

.rightsideItem > span {
  cursor: pointer;
}

/* #navbarCollapse ul li a {
  text-decoration: auto;
  color: #000;
} */

.headerSection {
  background-color: #ffffff;
}

.allItemsSection {
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: space-between;
}

.job-card-dashboard-alerts {
  background: #35ffc3;
  width: 60%;
  text-align: center;
  padding-bottom: 1px;
  /* // padding-top: 5px; */
  /* margin-bottom: 5px; */
  border-radius: 4px;
  display: flex;
  /* padding: 5px; */
  gap: 30px;

  /* h3 {
    color: #ed1e24;
    text-decoration: underline;
    margin-bottom: 7px;
    font-size: 20px;
    width: 10%;
  }
  p {
    color: #204289;
  } */
}

.job-card-dashboard-alerts h3 {
  color: #ed1e24;
  text-decoration: underline;
  margin-bottom: 0px;
  font-size: 20px;
  width: 10%;
}

.job-card-dashboard-alerts p {
  color: #204289;
}

.alerts-text-row {
  width: 90%;
}

.alerts-text-row p {
  margin-left: 100px;
  cursor: pointer;
  margin-bottom: 5px;
}

.allItemsSection > div {
  text-align: left;
  /* height: 70px; */
}

.cartIconPage {
  margin-top: 4px;
}

/* .leftsideItem {
  width: 7%;
}

.rightsideItem {
  width: 35%;
}
.centersideItem {
  width: 58%;
} */
.rightsideItem {
  display: flex;
  justify-content: right;
  /* padding-top: 5px; */
}

.profileIconStyles {
  width: 38px;
  height: 38px;
  /* background-color: #ccc; */
  color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

.profileIconStyles svg {
  height: 33px;
  width: 33px;
}

.header-popover-box svg.Popover-tip {
  margin-top: 8px;
  fill: #ff5733;
}

.elementor-icon-list-items-header {
  padding-top: 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  gap: 25px;
}

.elementor-icon-list-items-header a {
  color: #fff;
  padding: 5px 10px;
  text-decoration: none;
}

.elementor-icon-list-items-header a:hover {
  color: #ff5733;
  background: #fff;
  padding: 5px 10px;
  border-radius: 12px;
  text-decoration: none;
}

.elementor-icon-list-items-header::before {
  width: 100%;
  transition: all 0.3s ease-in-out;
}

.menuDropdownMainSection {
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  width: 200px;
  background-color: #fff;
  padding: 10px 20px;

  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.18);
  right: 23px;
  padding: 5px;
  border-radius: 3px;
}

.menuDropdownMainSection .items-list {
  cursor: pointer;
  margin: 10px 0px;
  padding: 5px 0px;
  padding-left: 10px;
}

.Popover-body .menuDropdownMainSection > div:not(.items-list-signout) {
  border-bottom: 1px solid #e2e2e2;
}

.filter-btn-btn {
  text-transform: capitalize;
  background: steelblue;
  line-height: 35px;
  border-radius: 5px;
  min-width: 100px;
  text-align: center;
  color: #fff;
  border: none;
  padding: 0px 40px;
  height: 40px;
  font-size: 20px;
  margin-top: -2px;
}

.header-login-btns {
  display: flex;
  gap: 30px;
}

.header-login-btns .sign-up {
  background: #fff;
  color: #ff5733;
  font-size: 17px;
  font-weight: 600;
}
.forgot-pass-model-sec .modal-dialog {
  max-width: 600px;
}

/* .Select {
  width: 444px !important;
  margin-right: 5px;
  z-index: 0 !important;
}

.Select.is-open {
  z-index: 1 !important;
}
.Select-menu {
  position: fixed !important;
}
.Select.is-open {
  z-index: 1000 !important;
}
.Select-menu {
  position: fixed !important;
  width: 440px !important;
  margin: 1px;
} */
