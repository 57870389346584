#root {
  background: #f0f3f6;
}
.layout-container {
  padding-top: 10px;
  padding-top: 70px;
  margin: auto;
}
.layout-container2 {
  padding-top: 70px;
  margin: auto;
}

.layout-container {
  display: flex;
}
.body-sec-content-full {
  min-height: 600px;
}
.left-menu-container {
  width: 330px;
  position: fixed;
  /* background-color: #e2e2e2; */
  border-radius: 2px;
  /* box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.08); */
  margin-bottom: 1rem;
}
body {
  background: #fff;
}
.left-menu-container,
.side-bar-container button.accordion-button {
  background-color: #ff6b4b;
}
.body-layout-container {
  width: calc(100% - 330px);
  margin-left: 1rem;
  margin-right: 5px;
  margin-left: 330px;
  margin-bottom: 1rem;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  padding: 10px 20px 20px 20px;
}
@media (max-width: 700px) {
  .body-layout-container {
    width: 100%;
    padding: 0px;
    margin-left: 0px;
  }
  .section-container-header-new {
    padding: 0px !important;
  }
}
